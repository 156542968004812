import React, { lazy, Suspense } from 'react';
// import { Switch } from 'react-router-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoutes from './shared/Routes/PrivateRoutes';
import BeforeLoginRoutes from './shared/Routes/BeforeLoginRoutes';
import LoadingScreen from './components/LoadingScreen';
import { useSelector } from 'react-redux';
const PageNotFound = lazy(() => import('./containers/notfound/PageNotFound'));
const Home = lazy(() => import('./containers/home/Home'));
const Shop = lazy(() => import('./containers/shop/Shop'));
const Single_shop = lazy(() => import('./containers/shop/Single_shop'));
const Contact = lazy(() => import('./containers/contactus/Contact'));
const About = lazy(() => import('./containers/aboutus/About'));
const Checkout = lazy(() => import('./containers/checkout/Checkout'));
const MyAccount = lazy(() => import('./containers/my-account/MyAccount'));
const Login = lazy(() => import('./containers/login/Login'));
const Wishlist = lazy(() => import('./containers/wishlist/Wishlist'));
const Privacy = lazy(() => import('./containers/policy/Privacy'));
const TermsCondition = lazy(() => import('./containers/policy/TermsCondition'));
const Cart = lazy(() => import('./containers/cart/Cart'));
const ForgetPassword = lazy(() => import('./containers/login/ForgetPassword'));
const ResetPassword = lazy(() => import('./containers/login/ResetPassword'));
const ThankYou = lazy(() => import('./containers/thankyou/ThankYou'));
const CollectionArchive = lazy(() =>
  import('./containers/collections/CollectionArchive')
);
const BikeArchieve = lazy(() => import('./containers/bikes/BikesArchieve'));
const WhyChoose = lazy(() => import('./containers/whychoose/WhyChoose'));
const ShippingPolicy = lazy(() =>
  import('./containers/shippingpolicy/ShippingPolicy')
);
const ReturnAndRefund = lazy(() =>
  import('./containers/returnandrefund/ReturnAndRefund')
);
const Cancellation = lazy(() => import('./containers/policy/Cancellation'));

const Blogs = lazy(() => import('./containers/blog/Blogs'));
const SingleBlog = lazy(() => import('./containers/blog/SingleBlog'));
const BlogsByCategory = lazy(() => import('./containers/blog/BlogsByCategory'));

export const Routes = () => {
  const options = {
    sitemapIndex: true,
    priority: 1,
    changefreq: 'weekly',
  };
  const data = useSelector((state) => state.homepage);
  const { sitemap_links } = data;
  console.log('SITEMAP LINKS -', sitemap_links);
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        <Route exact path='/' component={Home} {...options} />
        <Route exact path='/shop' component={Shop} {...options} />

        <Route
          exact
          path='/products/:slug'
          component={Single_shop}
          {...options}
          slugs={sitemap_links && sitemap_links.products}
        />
        <Route exact path='/contact' component={Contact} {...options} />
        <Route exact path='/about' component={About} {...options} />
        <Route exact path='/checkout' component={Checkout} {...options} />
        <PrivateRoutes
          exact
          path='/my-account'
          component={MyAccount}
          {...options}
        />
        {/* <Route exact path="/login" component={Login} /> */}
        <Route exact path='/wishlist' component={Wishlist} {...options} />
        <Route exact path='/privacy-policy' component={Privacy} {...options} />
        <Route exact path='/cart' component={Cart} {...options} />
        <Route exact path='/thank-you' component={ThankYou} {...options} />
        <Route
          exact
          path='/cancellation-policy'
          component={Cancellation}
          {...options}
        />
        <Route
          exact
          path='/forget-password'
          component={ForgetPassword}
          {...options}
        />
        <Route
          exact
          path='/reset-password/:token'
          component={ResetPassword}
          {...options}
        />
        <BeforeLoginRoutes exact path='/login' component={Login} {...options} />

        <Route
          exact
          path='/terms-and-condition'
          component={TermsCondition}
          {...options}
        />
        <Route
          exact
          path='/collections/:slug'
          component={CollectionArchive}
          {...options}
          slugs={sitemap_links && sitemap_links.collections}
        />
        <Route
          exact
          path='/bikes/:slug'
          component={BikeArchieve}
          {...options}
        />
        <Route
          exact
          path='/terms-and-conditions'
          component={TermsCondition}
          {...options}
        />
        <Route
          exact
          path='/return-and-refund'
          component={ReturnAndRefund}
          {...options}
        />
        <Route exact path='/why-choose' component={WhyChoose} {...options} />
        <Route
          exact
          path='/shipping-policy'
          component={ShippingPolicy}
          {...options}
        />
        <Route
          exact
          path='/blogs/:slug'
          component={SingleBlog}
          {...options}
          slugs={sitemap_links && sitemap_links.blogs}
        />
        <Route exact path='/blogs' component={Blogs} {...options} />
        <Route
          exact
          path='/blogs/category/:slug'
          component={BlogsByCategory}
          {...options}
          slugs={sitemap_links && sitemap_links.blog_categories}
        />
        <Route component={PageNotFound} />
      </Switch>
    </Suspense>
  );
};
