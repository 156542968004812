import React from 'react';

function LoadingScreen() {
  return (
    <div className='loading-icon'>
      <div className='loading-gif'>
        <h1> PGX PITSTOP </h1>
      </div>
      {
        // <div className="loading-text">
        //     <p>Loading. . . .</p>
        // </div>
      }
    </div>
  );
}

export default LoadingScreen;
