import React, { useState, useEffect } from 'react';
import './assets/css/style.css';
import './assets/css/custom.css';
import './assets/css/latest.css';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import setAuthToken from './domain/setAuthToken';

import { Routes } from './Routes';
import { getSitemapLink } from './store/actions/homepage_action';
import { loadUser } from './store/actions/auth';

function App() {
  useEffect(() => {
    //First we have to bring(get that) token, which is saved in local storage
    const token = localStorage.getItem('token');
    //then we will pass that token in setAuthToken method
    setAuthToken(token);
    store.dispatch(loadUser());
    store.dispatch(getSitemapLink());
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {
        //   loading === false ? (
        //   ): (
        //       <LoadingScreen />
        // )
      }

      <Provider store={store}>
        <Router>
          {/* <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/shop' component={Shop} />
            <Route exact path='/sitemap' component={Sitemap} />
            <Route exact path='/products/:slug' component={Single_shop} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/about' component={About} />
            <Route exact path='/checkout' component={Checkout} />
            <PrivateRoutes exact path='/my-account' component={MyAccount} />

            <Route exact path='/wishlist' component={Wishlist} />
            <Route exact path='/privacy-policy' component={Privacy} />
            <Route exact path='/cart' component={Cart} />
            <Route exact path='/thank-you' component={ThankYou} />
            <Route exact path='/cancellation-policy' component={Cancellation} />
            <Route exact path='/forget-password' component={ForgetPassword} />
            <Route
              exact
              path='/reset-password/:token'
              component={ResetPassword}
            />
            <BeforeLoginRoutes exact path='/login' component={Login} />

            <Route
              exact
              path='/terms-and-condition'
              component={TermsCondition}
            />
            <Route
              exact
              path='/collections/:slug'
              component={CollectionArchive}
            />
            <Route exact path='/bikes/:slug' component={BikeArchieve} />
            <Route
              exact
              path='/terms-and-conditions'
              component={TermsCondition}
            />
            <Route
              exact
              path='/return-and-refund'
              component={ReturnAndRefund}
            />
            <Route exact path='/why-choose' component={WhyChoose} />
            <Route exact path='/shipping-policy' component={ShippingPolicy} />
            <Route exact path='/blogs/:slug' component={SingleBlog} />
            <Route exact path='/blogs' component={Blogs} />
            <Route
              exact
              path='/blogs/category/:slug'
              component={BlogsByCategory}
            />
            <Route component={PageNotFound} />
          </Switch> */}
          <Routes />
        </Router>
      </Provider>
    </>
  );
}

export default App;
