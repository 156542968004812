export const GET_HOMEPAGES_STATED = 'GET_HOMEPAGES_STATED';
export const GET_HOMEPAGES = 'GET_HOMEPAGES';
export const GET_HOMEPAGES_ENDED = 'GET_HOMEPAGES_ENDED';
export const ADD_HOMEPAGE_STATED = 'ADD_HOMEPAGE_STARTED';
export const ADD_HOMEPAGE = 'ADD_HOMEPAGE';
export const ADD_HOMEPAGE_ENDED = 'ADD_HOMEPAGE_ENDED';
export const EDIT_HOMEPAGE_STATED = 'EDIT_HOMEPAGE_STATED';
export const EDIT_HOMEPAGE = 'EDIT_HOMEPAGE';
export const EDIT_HOMEPAGE_ENDED = 'EDIT_HOMEPAGE_ENDED';
export const GET_HOMEPAGE = 'GET_HOMEPAGE';
export const GET_HOMEPAGE_STATED = 'GET_HOMEPAGE_STATED';
export const GET_HOMEPAGE_ENDED = 'GET_HOMEPAGE_ENDED';
export const RESET_HOMEPAGE = 'RESET_HOMEPAGE';
export const ERROR_HOMEPAGE = 'ERROR_HOMEPAGE';
export const GET_ALL_HOMEPAGES_STATED = 'GET_ALL_HOMEPAGES_STATED';
export const GET_ALL_HOMEPAGES = 'GET_ALL_HOMEPAGES';
export const GET_ALL_HOMEPAGES_ENDED = 'GET_ALL_HOMEPAGES_ENDED';

export const GET_ALL_SITEMAP = 'GET_ALL_SITEMAP';
