export const GET_BLOGCOMMENTS_STATED = "GET_BLOGCOMMENTS_STATED";
export const GET_BLOGCOMMENTS = "GET_BLOGCOMMENTS";
export const GET_BLOGCOMMENTS_ENDED = "GET_BLOGCOMMENTS_ENDED";
export const ADD_BLOGCOMMENT_STATED = "ADD_BLOGCOMMENT_STARTED";
export const ADD_BLOGCOMMENT = "ADD_BLOGCOMMENT";
export const ADD_BLOGCOMMENT_ENDED = "ADD_BLOGCOMMENT_ENDED";
export const EDIT_BLOGCOMMENT_STATED = "EDIT_BLOGCOMMENT_STATED";
export const EDIT_BLOGCOMMENT = "EDIT_BLOGCOMMENT";
export const EDIT_BLOGCOMMENT_ENDED = "EDIT_BLOGCOMMENT_ENDED";
export const GET_BLOGCOMMENT = "GET_BLOGCOMMENT";
export const GET_BLOGCOMMENT_STATED = "GET_BLOGCOMMENT_STATED";
export const GET_BLOGCOMMENT_ENDED = "GET_BLOGCOMMENT_ENDED";
export const RESET_BLOGCOMMENT = "RESET_BLOGCOMMENT";
export const ERROR_BLOGCOMMENT = "ERROR_BLOGCOMMENT";
export const GET_ALL_BLOGCOMMENTS_STATED = "GET_ALL_BLOGCOMMENTS_STATED";
export const GET_ALL_BLOGCOMMENTS = "GET_ALL_BLOGCOMMENTS";
export const GET_ALL_BLOGCOMMENTS_ENDED = "GET_ALL_BLOGCOMMENTS_ENDED";


